export const employeeCompetencesFullResult = {
  Properties: {
    UserId: 1,
    CompetenceList: [
      {
        GroupId: 1,
        GroupName: 'Language Skills',
        Items: [
          {
            Id: 1,
            CompetenceId: 1,
            Name: 'Danish',
            Value: 5,
          },
          {
            Id: 6,
            CompetenceId: 6,
            Name: 'German',
            Value: 3,
          },
        ],
      },
      {
        GroupId: 2,
        GroupName: 'Project Management',
        Items: [
          {
            Id: 2,
            CompetenceId: 3,
            Name: 'Agile / Scrum',
            Value: 3,
          },
          {
            Id: 3,
            CompetenceId: 4,
            Name: 'Finance control',
            Value: 5,
          },
        ],
      },
      {
        GroupId: 4,
        GroupName: 'Change Management Tools',
        Items: [
          {
            Id: 10,
            CompetenceId: 10,
            Name: 'Lego Serious Play',
            Value: 3,
          },
        ],
      },
      {
        GroupId: 3,
        GroupName: 'Development',
        Items: [
          {
            Id: 22,
            CompetenceId: 8,
            Name: 'Python',
            Value: 3,
          },
          {
            Id: 24,
            CompetenceId: 9,
            Name: 'Java',
            Value: 3,
          },
        ],
      },
    ],
  },
  Links: [
    {
      Href: 'https://app8.timelog.com/npo_sandbox/api/v2/employee-competences/user/1',
      Rel: 'self',
    },
  ],
};
