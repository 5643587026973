import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { IUserMe } from 'src/apis/types/userMeAPI';

const ME_URL_PATH = '/api/v2/users/me';
export const ME_MSW_STATUS_KEY = 'MeAPI';

export const ME_KEY = 'ME';

const getUserPersonalInfo = (): Promise<IUserMe> =>
  getFetch({ path: ME_URL_PATH, key: ME_MSW_STATUS_KEY });

export const useGetUserInfo = () => {
  const { data, ...restProps } = useQuery([ME_KEY], getUserPersonalInfo);
  const userInfo = data?.properties ?? {};
  const id = data?.properties?.iD;
  const userId = data?.properties?.userId;
  return {
    userInfo: { ...userInfo, userId, id, iD: undefined },
    ...restProps,
  };
};

export const useGetUserId = () => {
  const { data, ...restProps } = useQuery([ME_KEY], getUserPersonalInfo);
  const { userId } = data?.properties ?? {};

  return {
    userId,
    ...restProps,
  };
};

export const useGetProfilePicture = () => {
  const { data, ...restProps } = useQuery([ME_KEY], getUserPersonalInfo);
  const { profilePicture } = data?.properties ?? {};

  return {
    profilePicture,
    ...restProps,
  };
};

export const useGetUserEmail = () => {
  const { data, ...restProps } = useQuery([ME_KEY], getUserPersonalInfo);
  const { email } = data?.properties ?? {};

  return {
    email,
    ...restProps,
  };
};

export const useGetUserFullName = () => {
  const { data, ...restProps } = useQuery([ME_KEY], getUserPersonalInfo);
  const { fullName } = data?.properties ?? {};

  return {
    fullName,
    ...restProps,
  };
};

export const useGetFirstName = () => {
  const { data, ...restProps } = useQuery([ME_KEY], getUserPersonalInfo);
  const { firstName } = data?.properties ?? {};

  return {
    firstName,
    ...restProps,
  };
};

export const useHelpURLInfo = () => {
  const { data, ...restProps } = useQuery([ME_KEY], getUserPersonalInfo);
  const { email = '', initials = '' } = data?.properties ?? {};
  return {
    helpInfo: { email, initials },
    ...restProps,
  };
};
