import { useQuery } from '@tanstack/react-query';
import { IFilterStoreProps } from 'src/reducers/FilterReducer';
import { getFetch } from 'src/utils/fetcher';
import { concatQuerys } from 'src/utils/url';
import { toCamel } from 'src/utils/object';
import getSearchProjects from 'src/mocks/searchProjects/getSearchProjects';
import { ISearchProjectsResult } from '../types';

const SEARCH_PROJECTS_URL_PATH = '/api/v2/search-projects';
export const SEARCH_PROJECTS_MSW_STATUS_KEY = 'GetSearchProjectsPI';
export const SEARCH_PROJECTS_KEY = 'SEARCH_PROJECTS';

const searchProjectsFetch = (
  filters: string,
  params: { [key: string]: string },
): Promise<ISearchProjectsResult> =>
  getFetch({
    path: `${SEARCH_PROJECTS_URL_PATH}${concatQuerys(
      Object.keys(params).map((key) => ({
        param: key.replaceAll('-', ''),
        value: params[key].replaceAll('-', ''),
      })),
    )}`.concat(filters),
    key: SEARCH_PROJECTS_MSW_STATUS_KEY,
  });

export const useGetSearchProjects = (
  { selectedFilterList }: Pick<IFilterStoreProps, 'selectedFilterList'>,
  params?: { [key: string]: string },
) => {
  const items = Object.keys(selectedFilterList);
  const filtersParam = `${concatQuerys(
    items
      .filter(
        (item) => selectedFilterList[item].values.map((value) => value.value).toString() !== '',
      )
      .map((item) => ({
        param: `${item}Ids`,
        value: selectedFilterList[item].values.map((value) => value.value).toString(),
        isAppended: true,
      })),
  )}${concatQuerys(
    items
      .filter(
        (item) => selectedFilterList[item].values.map((value) => value.value).toString() !== '',
      )
      .map((item) => ({
        param: `Is${item}Inclusive`,
        value: String(selectedFilterList[item].isInclude),
        isAppended: true,
      })),
  )}`;

  const {
    data: {
      model: { properties: { children = [], viewOptions = {}, responseType = '' } = {} } = {},
    } = {},
    isError,
    isSuccess,
    ...restProps
  } = useQuery(
    [SEARCH_PROJECTS_KEY, params || {}, selectedFilterList || {}],
    async () => {
      const response = await searchProjectsFetch(filtersParam, params || {});

      return response;
    },
    {
      // any previous data will be kept when fetching new data because the query key changed.
      keepPreviousData: true,
      staleTime: Infinity,
      retry: 0,
    },
  );

  // For testing, to be remove
  if (isError) {
    const {
      model: { properties },
    } = toCamel(getSearchProjects) as ISearchProjectsResult;
    return {
      children: properties?.children || [],
      isError: false,
      isEmpty: false,
      isSuccess: true,
      ...restProps,
    };
  }

  return {
    children,
    isEmpty: children.length <= 0,
    isError,
    isSuccess,
    responseType,
    viewOptions,
    ...restProps,
  };
};
