import { Box } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';
import {
  Button,
  DataGrid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from 'src/components/mui-components';
import { Circle, MonetizationOnOutlined } from '@mui/icons-material';

interface IViewTextDialog {
  showTimeRegistration: boolean;
  setShowTimeRegistration: (value: boolean) => void;
}

const bookStatusOptions = ['booked', 'draft', 'nonBooked'];
const billableOptions = ['billable', 'nonBillable'];

// Mock data, to be return from BE
const rows = [
  {
    id: 1,
    date: '01-01-2022',
    project: 'SYN - Lønbehandling Outsourcing (FPVI DKK)',
    task: 'Indsamling af løndata',
    employee: 'ANI',
    comment: 'Comment',
    secondaryComment: '',
    currency: 'DKK',
    actualHour: '2,00',
    hourlyRate: '450,00',
    factor: '100%',
    actual: '900,00',
    status: true,
    bookStatus: 'booked',
  },
  ...Array.from({ length: 5 }, (_, i) => ({
    id: i + 2,
    date: `0${i + 2}-01-2022`,
    project: `Project ${i + 2}`,
    task: `Task ${i + 2}`,
    employee: `KLA`,
    comment: `Comment ${i + 2}`,
    secondaryComment: '',
    currency: 'DKK',
    actualHour: `${i + 2},00`,
    hourlyRate: `${(i + 2) * 100},00`,
    factor: '100%',
    actual: `${(i + 2) * 100 * (i + 2)},00`,
    billable: billableOptions[Math.floor(Math.random() * billableOptions.length)],
    bookStatus: bookStatusOptions[Math.floor(Math.random() * bookStatusOptions.length)],
  })),
];

const columns: GridColDef[] = [
  { field: 'date', headerName: 'Date', width: 100 },
  { field: 'project', headerName: 'Project', width: 250 },
  {
    field: 'task',
    headerName: 'Task',
    width: 250,
  },
  {
    field: 'employee',
    headerName: 'Employee',
    width: 100,
  },
  {
    field: 'comment',
    headerName: 'Comment',
    width: 150,
  },
  {
    field: 'secondaryComment',
    width: 150,
    headerName: 'Secondary comment',
  },
  {
    field: 'currency',
    width: 80,
    headerName: 'Currency',
  },
  {
    field: 'actualHour',
    width: 100,
    headerName: 'Actual (h.)',
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'hourlyRate',
    width: 100,
    headerName: 'Hourly rate',
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'factor',
    width: 100,
    headerName: 'Factor',
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'actual',
    width: 100,
    headerName: 'Actual',
    align: 'right',
    headerAlign: 'right',
  },
  {
    field: 'billable',
    width: 60,
    headerName: '',
    renderCell: (params: GridRenderCellParams) => {
      const billableMap = {
        billable: {
          color: 'success',
          text: 'Billable registration on a billable task',
        },
        nonBillable: {
          color: 'error',
          text: 'Non-billable registration on a billable task',
        },
      };

      const { color, text } =
        billableMap[params.value as 'billable' | 'nonBillable'] || billableMap.nonBillable;

      return (
        <IconButton data-automation-id="BillableInfoButton" title={text}>
          <MonetizationOnOutlined fontSize="small" color={color as 'success' | 'error'} />
        </IconButton>
      );
    },
  },
  {
    field: 'bookStatus',
    headerName: '',
    width: 60,
    renderCell: (params: GridRenderCellParams) => {
      const statusMap = {
        booked: {
          color: 'success',
          text: 'Booked. Click the icon to go to the invoice (immediately invoiced items) or the voucher.',
        },
        draft: {
          color: 'warning',
          text: 'On invoice/voucher draft. Click the icon to go to the invoice or voucher.',
        },
        nonBooked: {
          color: 'disabled',
          text: 'Non-booked',
        },
      };

      const { color, text } =
        statusMap[params.value as 'booked' | 'draft' | 'nonBooked'] || statusMap.nonBooked;

      return (
        <IconButton title={text} data-automation-id="bookedIcon">
          <Circle fontSize="small" color={color as 'success' | 'warning' | 'disabled'} />
        </IconButton>
      );
    },
  },
];
const CustomToolbar = () => (
  <GridToolbarContainer>
    <Box display="flex" justifyContent="space-between" width="100%">
      <Stack alignItems="left">
        <Stack direction="row" alignItems="center">
          <Button variant="outlined" size="small">
            Time registrations (new)
          </Button>
          <Button variant="outlined" size="small">
            Manage hourly rate
          </Button>
          <Button variant="outlined" size="small">
            Adjust Billable/ Non-billable
          </Button>
          <Button variant="outlined" size="small">
            Move allocations
          </Button>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="flex-end" alignItems="center">
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      </Stack>
    </Box>
  </GridToolbarContainer>
);

const CustomToolbarWithSelection = () => <CustomToolbar />;

export const TimeRegistrationsDialog = ({
  showTimeRegistration,
  setShowTimeRegistration,
}: IViewTextDialog) => (
  // const { t } = useTranslation('searchProject');
  <Dialog
    open={!!showTimeRegistration}
    onClose={() => setShowTimeRegistration(false)}
    aria-labelledby="dialog-text"
    aria-describedby="dialog-text-description"
    fullWidth
    maxWidth="lg"
  >
    <DialogTitle id="dialog-text">Time registrations</DialogTitle>
    <DialogContent>
      <Box sx={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnTopBorder
          hideFooter
          slots={{
            // eslint-disable-next-line react/no-unstable-nested-components
            toolbar: () => <CustomToolbarWithSelection />,
          }}
        />
      </Box>
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" onClick={() => setShowTimeRegistration(false)}>
        Cancel{' '}
      </Button>
    </DialogActions>
  </Dialog>
);
