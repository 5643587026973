import { Clear } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import { KeyboardEvent, MutableRefObject, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import { IconButton, TextField } from 'src/components/mui-components';
import { convertNumber, formatNumber, validateNumberInput } from 'src/utils/number';

interface IHoursInput {
  error: string;
  label: string;
  onChange: ({ hours, hoursInt }: { hours: string; hoursInt: number }) => void;
  setError: (error: string) => void;
  value: string;
  /**
   * Overrides input clear button action.
   */
  clearAction?: () => void;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
  onBlur?: () => void;
  onKeyDown?: (event: KeyboardEvent<HTMLDivElement>) => void;
  required?: boolean;
}

export const HoursInput = ({
  clearAction,
  error,
  inputRef,
  label,
  onBlur,
  onChange,
  onKeyDown,
  required,
  setError,
  value,
}: IHoursInput) => {
  const { t } = useTranslation('assignFlow');
  const siteLocale = useGetLocale();

  const textFieldOnBlur = () => {
    const { status, value: v } = validateNumberInput(value, t('DecimalFormatValidation'));
    setError(status === 'error' ? v : '');
    onBlur?.();
  };

  const hoursOnChange = useCallback(
    (h: string) => {
      const decimalFormat = formatNumber(h, siteLocale);
      onChange?.({
        hours: h,
        hoursInt: convertNumber(decimalFormat, siteLocale),
      });
    },
    [onChange, siteLocale],
  );

  useEffect(() => {
    hoursOnChange(value);
  }, [value, hoursOnChange]);

  return (
    <TextField
      ariaLabel={label}
      data-automation-id="Hours"
      error={!!error}
      errorMessage={error}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {value ? (
              <IconButton
                data-automation-id="HoursInputClearButton"
                onClick={() => {
                  if (clearAction) {
                    clearAction();
                    return;
                  }
                  hoursOnChange('');
                  setError('');
                }}
                size="small"
                title={t('ClearHoursButtonText')}
              >
                <Clear fontSize="small" />
              </IconButton>
            ) : null}
          </InputAdornment>
        ),
        inputProps: { autoFocus: true },
      }}
      inputRef={inputRef}
      label={label}
      onBlur={textFieldOnBlur}
      onChange={(e) => hoursOnChange(e.target.value)}
      onKeyDown={onKeyDown}
      placeholder={formatNumber(0, siteLocale)}
      required={required}
      size="small"
      status={error ? 'error' : 'info'}
      sx={{ width: 'calc((100% - 23px) / 2)' }}
      usePopOutValidation
      value={value}
    />
  );
};
