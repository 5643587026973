import { ClickAwayListener, Paper, Popper, Stack } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import { FilterTag } from 'src/components/layout/FilterLayout/components/MainContainer/components/FilterTag';
import { useMenu } from 'src/hooks/useMenu';
import { getDateStringFromSiteLocale } from 'src/utils/date';
import { translationAnyText } from 'src/utils/translation';
import { filterOnKeyDown } from '../../../helpers';
import { useGetDefaultDates } from '../../../hooks';
import { AvailabilityDateRangePicker } from '../../AvailabilityDateRangePicker';
import { HoursInput } from '../../HoursInput';
import styles from './FilterItemAvailability.module.scss';
import { IFilterAvailability } from './FilterItemAvailability.types';

interface IFilterItemAvailability {
  dates: DateRange<Date>;
  onChange: (a: IFilterAvailability) => void;
  value: IFilterAvailability;
}

export const FilterItemAvailability = ({
  dates: [startsAt, endsAt],
  onChange,
  value,
}: IFilterItemAvailability) => {
  const { t } = useTranslation('assignFlow');
  const siteLocale = useGetLocale();

  const ref = useRef<HTMLDivElement>(null);
  const { anchorEl, menuIsOpen, menuOnClose, setAnchorEl } = useMenu();

  const { periodEnd, periodStart } = value;
  const [hours, setHours] = useState('');
  const [hoursInt, setHoursInt] = useState(0);
  const [hoursError, setHoursError] = useState('');
  const [date, setDate] = useState<DateRange<Date>>([periodStart, periodEnd]);
  const [dateError, setDateError] = useState(false);

  const [startDate, endDate] = date;
  const [defaultStartDate, defaultEndDate] = useGetDefaultDates([startsAt, endsAt]);
  const startDateChanged = startDate?.toDateString() !== defaultStartDate.toDateString();
  const endDateChanged = endDate?.toDateString() !== defaultEndDate.toDateString();
  const availabilityFilterActive = hours || startDateChanged || endDateChanged;
  const availabilityFilterError = hoursError || dateError;

  const label = (() => {
    const noHours = hours === '' || hoursError;
    const noDates = !startDateChanged && !endDateChanged;

    const end = endDate ? getDateStringFromSiteLocale(endDate, siteLocale) : '-';
    const start = startDate ? getDateStringFromSiteLocale(startDate, siteLocale) : '-';

    if (noHours && noDates) {
      return t('FilterItems.Any');
    }
    if (noHours) {
      return t('FilterItems.AnyBetween', { endDate: end, startDate: start });
    }
    if (noDates) {
      return t('FilterItems.HoursNoBetween', { hours: hoursInt });
    }

    return t('FilterItems.HoursBetween', { endDate: end, hours: hoursInt, startDate: start });
  })();

  const popperOnClose = () => {
    if (hoursError || !startDate || !endDate || dateError) {
      return;
    }
    menuOnClose(() => {
      onChange({
        hours: hoursInt || undefined,
        periodEnd: endDate,
        periodStart: startDate,
      });
    });
  };

  const filterTagOnRemove = () => {
    menuOnClose();
    onChange({ periodEnd: defaultEndDate, periodStart: defaultStartDate });
    setHours('');
    setHoursInt(0);
    setHoursError('');
    setDate([defaultStartDate, defaultEndDate]);
    setDateError(false);
  };

  return (
    <FilterTag
      data-automation-id="FilterItem-Availability"
      items={
        availabilityFilterActive
          ? [{ label }]
          : [{ label: translationAnyText(t, `FilterItems.Any`) }]
      }
      onClick={(e) => setAnchorEl(e.currentTarget)}
      panelIsOpen={menuIsOpen}
      ref={ref}
      removeFilterTag={
        availabilityFilterActive || availabilityFilterError ? filterTagOnRemove : undefined
      }
      title={translationAnyText(t, `FilterItems.Availability`)}
    >
      {menuIsOpen ? (
        <Popper
          anchorEl={anchorEl}
          className={styles.popper}
          data-automation-id="FilterItem-Availability-Popper"
          disablePortal
          onKeyDown={(e) => filterOnKeyDown({ e, popperOnClose, ref })}
          open={menuIsOpen}
          placement="bottom-start"
        >
          <ClickAwayListener onClickAway={popperOnClose}>
            <Paper className={styles.paper} elevation={4}>
              <Stack gap={2} m={1}>
                <HoursInput
                  error={hoursError}
                  label={t('FilterItems.Hours')}
                  onChange={({ hours: h, hoursInt: hi }) => {
                    setHours(h);
                    setHoursInt(hi);
                  }}
                  setError={setHoursError}
                  value={hours}
                />
                <AvailabilityDateRangePicker
                  onChange={setDate}
                  onError={setDateError}
                  value={date}
                />
              </Stack>
            </Paper>
          </ClickAwayListener>
        </Popper>
      ) : null}
    </FilterTag>
  );
};
