import { useQuery } from '@tanstack/react-query';
import { IFilterOptions } from 'src/apis/types/dropdownAPI';
import { getFetch } from 'src/utils/fetcher';
import { concatQuerys } from 'src/utils/url';

export interface IDropdown {
  label: string;
  value: string;
}

interface IGetDropDownAPI {
  path: string;
  MSWKey: string;
  key: string;
  searchText?: string;
  startDate?: string;
  endDate?: string;
}

const dropdownFetch = (
  path: string,
  MSWKey: string,
  searchText?: string,
  startDate?: string,
  endDate?: string,
): Promise<IFilterOptions> =>
  getFetch({
    path: `${path}${concatQuerys(
      [
        {
          param: 'searchText',
          value: searchText,
        },
        {
          param: 'startDate',
          value: startDate,
        },
        {
          param: 'endDate',
          value: endDate,
        },
      ],
      true,
    )}`,
    key: MSWKey,
  });

export const useGetDropdownWithSearchAPI = (
  { key, path, MSWKey, searchText, startDate, endDate }: IGetDropDownAPI,
  enableQuery?: boolean,
) => {
  const { data, ...restProps } = useQuery(
    [`DropDown${key}`, { searchText, startDate, endDate }],
    () => dropdownFetch(path, MSWKey, searchText, startDate, endDate),
    {
      enabled: enableQuery,
      staleTime: 5000,
    },
  );
  return {
    dropdownList:
      data?.entities?.map<IDropdown>(({ properties: { displayText = '', value = '' } = {} }) => ({
        label: displayText,
        value,
      })) ?? [],
    dropdownLink: data?.links?.[0]?.href,
    ...restProps,
  };
};
