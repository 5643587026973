import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import FilterListIcon from '@mui/icons-material/FilterList';
import { ReactNode, RefObject, useEffect, useMemo, useState } from 'react';
import { ISavedView } from 'src/apis/savedViewAPI';
import { Badge, Button, Paper, Stack, Typography } from 'src/components/mui-components';

interface FilterContainerProps {
  openFilterPanel: () => void;
  activateSidePanelButtonRef?: RefObject<HTMLButtonElement>;
  activeFilters: number;
  clearAllFilters?: () => void;
  children?: ReactNode;
  tabRowTemplate?: ReactNode;
  searchInput?: ReactNode;
  primaryActions?: ReactNode;
  secondaryActions?: ReactNode;
  savedViewsList?: ISavedView[];
  filterButtonText?: string;
  noActiveFiltersText?: string;
  addFilterButtonText?: string;
  clearAllFiltersButtonText?: string;
  initialActivePanelIsOpen?: boolean;
  localStorageNamePrefix: string;
}

export const toggleFilterContainerEventName = 'filter-container-toggle';

export const FilterContainer = ({
  openFilterPanel,
  activateSidePanelButtonRef,
  clearAllFilters,
  children,
  tabRowTemplate,
  searchInput,
  activeFilters,
  primaryActions,
  secondaryActions,
  savedViewsList = [],
  filterButtonText = 'Filter',
  noActiveFiltersText = 'Add filters to narrow down results',
  addFilterButtonText = 'Add filter',
  clearAllFiltersButtonText = 'Clear filters',
  initialActivePanelIsOpen = false,
  localStorageNamePrefix = '',
}: FilterContainerProps) => {
  const localStoragePrefix = `${localStorageNamePrefix}-`;
  const filterIsOpenLocal = localStorage.getItem(`${localStoragePrefix}filter-is-open`) === 'true';
  const [filterIsOpen, setFilterIsOpen] = useState<boolean>(filterIsOpenLocal || false);
  useEffect(() => {
    localStorage.setItem(`${localStoragePrefix}filter-is-open`, JSON.stringify(filterIsOpen));
  }, [filterIsOpen, localStoragePrefix]);

  const [activeFiltersPanelIsOpen, setActiveFiltersPanelIsOpen] = useState(
    filterIsOpenLocal || initialActivePanelIsOpen,
  );

  const showResetButton = clearAllFilters && activeFilters;
  const hasSavedViews = useMemo(
    () => !!(savedViewsList.length || searchInput),
    [savedViewsList, searchInput],
  );

  const toggleFilterPanel = () => {
    setFilterIsOpen(!filterIsOpen);
    setActiveFiltersPanelIsOpen((prev) => !prev);
    window.dispatchEvent(new Event(toggleFilterContainerEventName));
  };

  useEffect(() => {
    localStorage.setItem(`${localStoragePrefix}filter-is-open`, JSON.stringify(filterIsOpen));
  }, [filterIsOpen, localStoragePrefix]);

  return (
    <Stack gap={2}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={hasSavedViews ? 'space-between' : 'right'}
        sx={{ borderBottom: hasSavedViews ? '1px solid #dedede' : 'none' }}
        gap={2}
      >
        {tabRowTemplate}

        <Stack direction="row" alignItems="center" flexShrink={0}>
          {searchInput}
          {primaryActions}

          <Button
            variant="text"
            startIcon={
              <Badge
                badgeContent={activeFilters}
                color="primary"
                data-automation-id="FilterContainerAddFilterCount"
              >
                <FilterListIcon />
              </Badge>
            }
            onClick={toggleFilterPanel}
            aria-controls="active-filters-container"
            aria-expanded={activeFiltersPanelIsOpen}
            data-automation-id="FilterContainerToggleFilter"
          >
            {filterButtonText}
          </Button>

          {secondaryActions}
        </Stack>
      </Stack>

      <Paper
        id="active-filters-container"
        hidden={!activeFiltersPanelIsOpen}
        data-automation-id="FilterContainerActiveFiltersContainer"
        variant="outlined"
        sx={{ padding: 2 }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="row" alignItems="center" sx={{ flexWrap: 'wrap' }}>
            {activeFilters ? (
              children
            ) : (
              <Typography
                component="p"
                fontStyle="italic"
                data-automation-id="FilterContainerNoResultsText"
              >
                {noActiveFiltersText}
              </Typography>
            )}
          </Stack>

          <Stack direction="row" alignItems="center" gap={2} flexShrink={0}>
            <Button
              variant="text"
              startIcon={<AddIcon />}
              size="small"
              onClick={openFilterPanel}
              data-automation-id="FilterContainerAddFilter"
              ref={activateSidePanelButtonRef}
            >
              {addFilterButtonText}
            </Button>
            {showResetButton ? (
              <Button
                variant="text"
                startIcon={<CloseIcon />}
                size="small"
                onClick={clearAllFilters}
                data-automation-id="FilterContainerRemoveFilters"
              >
                {clearAllFiltersButtonText}
              </Button>
            ) : null}
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default FilterContainer;
