import { Box, ListSubheader, MenuItem, Stack } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridColDef,
  GridCsvExportMenuItem,
  GridToolbarExportContainer,
} from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { Button, Typography, Select } from 'src/components/mui-components';
import { GridExcelExportMenuItem } from 'src/components/mui-components/DataGrid/utils';

interface IToolbarProps {
  isRowSelected: boolean;
  columns: GridColDef[];
}

const ActionsSelection = () => {
  const { t } = useTranslation('searchProjects');
  return (
    <Box sx={{ minWidth: 120 }}>
      <Select
        defaultValue="0"
        id="grouped-select"
        label="Actions"
        fullWidth
        size="small"
        MenuProps={{
          PaperProps: {
            sx: {
              '.MuiMenuItem-root span': {
                marginLeft: 2,
              },
            },
          },
        }}
        data-automation-id="ActionsSelectionSelect"
      >
        <MenuItem value={0}>{t('DropdownSelectActionText')}</MenuItem>
        <MenuItem value={1}>{t('DropdownDeleteActionText')}</MenuItem>
        <ListSubheader>
          <Typography color="primary">{t('DropdownChangeStatusToText')}</Typography>
        </ListSubheader>
        <MenuItem value={2}>
          <span>{t('StatusQuotationText')}</span>
        </MenuItem>
        <MenuItem value={4}>
          <span>{t('StatusApprovedText')}</span>
        </MenuItem>
        <MenuItem value={5}>
          <span>{t('StatusInProgressText')}</span>
        </MenuItem>
        <MenuItem value={6}>
          <span>{t('StatusOnHoldText')}</span>
        </MenuItem>
        <MenuItem value={7}>
          <span>{t('StatusCompletedText')}</span>
        </MenuItem>
        <MenuItem value={8}>
          <span>{t('StatusArchivedText')}</span>
        </MenuItem>
        <MenuItem value={9}>
          <span>{t('StatusCancelledText')}</span>
        </MenuItem>
        <ListSubheader>
          <Typography color="primary">{t('DropdownChangeStageToText')}</Typography>
        </ListSubheader>
        <MenuItem value={10}>
          <span>Initiation</span>
        </MenuItem>
        <MenuItem value={11}>
          <span>Planning</span>
        </MenuItem>
        <MenuItem value={12}>
          <span>Executing & Controlling</span>
        </MenuItem>
        <MenuItem value={13}>
          <span>Closing</span>
        </MenuItem>
        <ListSubheader>
          <Typography color="primary">Resource planner</Typography>
        </ListSubheader>
        <MenuItem value={14}>
          <span>{t('DropdownEnableResourcePlannerText')}</span>
        </MenuItem>
        <MenuItem value={15}>
          <span>{t('DropdownDisableResourcePlannerText')}</span>
        </MenuItem>
      </Select>
    </Box>
  );
};

export const Toolbar = ({ isRowSelected, columns }: IToolbarProps) => {
  const { t } = useTranslation('searchProjects');
  return (
    <GridToolbarContainer>
      <Box display="flex" justifyContent="space-between" width="100%">
        <Stack direction="row" alignItems="center">
          <Typography sx={{ mr: 2 }} variant="h6">
            <strong>{t('HeaderTitle')}</strong>
          </Typography>
          <>
            <ActionsSelection />
            <Button variant="text" disabled={!isRowSelected}>
              {t('ActionApplyButton')}
            </Button>
          </>
        </Stack>
        <Stack direction="row" justifyContent="flex-end" alignItems="center">
          <GridToolbarColumnsButton />
          <GridToolbarDensitySelector />
          <GridToolbarExportContainer>
            <GridCsvExportMenuItem options={{ disableToolbarButton: true }} />
            <GridExcelExportMenuItem columns={columns} />
          </GridToolbarExportContainer>
        </Stack>
      </Box>
    </GridToolbarContainer>
  );
};
